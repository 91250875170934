.featuredContainer {
  /* border: 2px solid #c2dbfe; */
  /* background-color: #c2dbfe; */
  /* background-color: #eff6ff; */
  /* min-height: 200px; */

  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 75%;
  padding: 15px 0;
  cursor: default;
}
.childiv {
  border: 2px solid #93beff;
  /* box-shadow: 10px 10px 10px 10px #5096ff; */
  /* background-color: #e2eeff; */
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  /* border: 1px solid red; */
  /* height: 100%; */
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.3s;
  /* align-items: end; */
  position: relative;
  box-shadow: 0px 10px 10px -5px lightgray;
}

.childiv > div {
  /* border: 1px solid blue; */
  /* height: 70%; */
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* .childiv a {
  background-color: rgb(162, 162, 162);
  font-weight: 500;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-decoration: none;
} */

.childiv a {
  /* background-color: rgb(102, 102, 102); */
  background-color: #5096ff;
  font-weight: 500;
  font-size: 12px;
  padding: 2px 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.2s;
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-weight: 600;
  text-decoration: none;
}
.childiv a:hover {
  transition: 0.2s;
  transform: scale(1.1);
  background-color: #2a7fff;
  /* background-color: rgb(143, 143, 143); */
}
.image {
  height: 80px;
  /* border: 1px solid red; */
}

.featured {
  /* background-color: #428dfd; */
  background-color: #5096ff;
  color: white;
  padding: 3px 5px;
  border-radius: 0px 3px 0px 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 0px;
  right: 0px;
}

@media screen and (max-width: 600px) {
  .featuredContainer {
    gap: 10px;
  }
  .childiv {
    width: 100%;
  }
}

/* .innerDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fcard {
  transform: scale(0.8);
  border: 3px solid #c2dbfe;
  /* ************************************under this new css */
/* } */

/* @media screen and (max-width: 440px) {
  .innerDiv > div { */
/* margin-top: -40px; */
/* }
} */
