.topContainer {
  display: flex;
  justify-content: space-between;
  min-width: 250px;
}

.vote {
  height: 30px;
  cursor: pointer;
  transition: 0.2s;
}

.vote:hover {
  transform: scale(1.1);
}

.featured {
  background-color: gray;
  color: white;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  border-radius: 0 0 0 3px;
  position: absolute;
  left: -1px;
  bottom: -16px;
  opacity: 0.6;
}

@media screen and (max-width: 600px) {
  .card {
    transform: scale(0.8);

    margin-bottom: -50px;
  }
}
