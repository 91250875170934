.subscribe {
  transform: scale(0.9);
  width: 50%;
}

.subscribeDiv {
  width: 75%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

/* a:hover {
  transform: scale(1.01);
  transition: 0.2s ease;
} */

.subsInput {
  width: 60%;
  border: none;
  outline: 2px solid gray;
  padding: 0px 10px 0px 15px;
  /* display: block; */
  border-radius: 10px;
  font-weight: 600;
  color: rgb(85, 84, 84);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.subsInput:hover {
  transform: scale(1.02);
  transition: 0.3s ease;
}

.subsInput:focus {
  outline: 2px solid black;
}

.btn {
  border-radius: 10px;
}
.btn:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1.02);
}

@media screen and (max-width: 990px) {
  .subscribeDiv {
    display: none;
  }
}

@media screen and (min-width: 990px) {
  .leftSubmit {
    display: none !important;
  }
}
/************/
.smallsubscribeDiv {
  width: 100%;
  /* border: 1px solid black; */
}

.smallsubscribe {
  width: 90%;
  margin-top: 15px;
}

.smallsubsInput {
  width: 100%;
  border: none;
  outline: 2px solid gray;
  padding: 0px 10px 0px 15px;
  /* display: block; */
  border-radius: 10px;
  font-weight: 600;
  color: rgb(85, 84, 84);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.smallsubsInput:focus {
  outline: 2px solid black;
}

@media screen and (min-width: 600px) {
  .smallsubscribe {
    width: 70%;
  }
}

@media screen and (min-width: 990px) {
  .smallsubscribeDiv {
    display: none;
  }
}
