.filterContainer {
  /* height: 65%; */
  width: 70%;
  /* z-index: 10; */
  /* position: fixed; */
  border-radius: 15px;
  outline: 3px solid #a4c8f9;
  background-color: #eff6ff;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.listItem {
  /* border: 1px solid black; */
  padding: 2px;
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  padding: 4px;
  border-radius: 5px;
  /* background-color: rgb(203, 236, 255); */
  /* outline: 1px solid black; */
  /* background-color: white; */
}
.checkbox {
  transform: scale(1.1);
  transition: 0.2s;
}

.checkbox:hover {
  transform: scale(1.3);
}

.btn {
  transform: scale(0.8);
  margin-left: 33px;
}

@media screen and (max-width: 600px) {
  .filterContainer {
    /* position: sticky;
    min-height: 200px;
    margin: auto;
    border-radius: 10px; */
    width: 90%;
    /* margin-bottom: 50px;
    outline: none;
    padding: 5px 0 5px 0; */
  }

  /* .list {
    height: 65%;
    width: 96%;
    overflow: visible;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3px;
  } */

  .listItem {
    /* border: 1px solid black; */
    /* padding: 2px; */
    font-size: 8px;
    font-weight: 500;
    /* margin-bottom: 5px; */
  }
  .checkbox {
    transform: scale(0.8);
  }

  .btn {
    transform: scale(0.8);
    position: relative;
    left: 10vw;
  }
}
