.container {
  /* border: 3px solid black; */
}

.formContainer {
  width: 50%;
}

.container button:hover {
  transform: scale(1.02);
  transition: 0.2s ease;
}
