.searchDiv {
  width: 50%;
  padding: 10px;
  /* background-color: gray; */
}
.searchDiv > form {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.searchInput {
  display: block;
  width: 80%;
  padding: 10px 20px 10px 20px;
  font-weight: 600;
  border-radius: 25px;
  border: none;
  /* outline: 3px solid #a9cbfe; */
  /* outline: 2px solid #ffba71; */
  outline: 2px solid #5396fa;
  box-shadow: 0px 8px 10px -5px #5396fa;
  transition: 0.2s ease-in-out;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.searchDiv button {
  /* height: 30px; */
}

.searchInput:hover {
  /* outline: 3px solid #247afb; */
  /* outline: 3px solid #fe8300; */
  outline: 2px solid #1e74f5;
  transform: scale(1.03);
}
.cardContianer {
  margin-bottom: 50px;
}

.loadmore {
  /* background-color: green; */
}
@media screen and (max-width: 990px) {
  .searchDiv {
    width: 75%;
  }
}

@media screen and (max-width: 600px) {
  .cardContianer {
    /* border: 1px solid black; */
    justify-content: end;
    width: 100%;
    margin-top: -20px;
  }
  .searchDiv {
    width: 95%;
  }
}

.spinner {
  /* border: 1px solid black; */
  margin-top: 20vh;
}
